* , *::before , *::after{

    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
}

:root {
    --primary-hue:210;
    --gray-hue: 240; 
    --color-primary:hsl(207.31deg 80.72% 32.55%);
    --color-primary-variant: hsl(var(--primary-hue), 100%, 65%);
    --color-secondary: #ffb116;
    --color-gray-100: hsl(var(--gray-hue),  47% , 94%);
    --color-gray-200: hsl(240deg 37.1% 9.07%);
    --color-gray-300: hsl(var(--gray-hue),  24% , 64%);
    --color-gray-400: hsl(var(--gray-hue),  16% , 41%);
    --color-gray-500: hsl(var(--gray-hue),  44% , 25%);
    --color-gray-600: hsl(0deg 0% 85.04%);
 
    --container-width-lg: 80%;
    --container-width-md: 90%;

    --transition:all 500ms ease;
}

body{
    /* background: var(--color-primary); */
    font-family: 'Times New Roman';
    color: var(--color-gray-200);
    line-height: 1.7;
    overflow-x: hidden;
    background: var(--color-gray-600);
}

.container {
    width: var(--container-width-lg);
    max-width: 1920px;
    margin-inline: auto;
}

h1, h2 , h3 ,h4 , h5 {
    line-height: 1.2;
    color: hsl(240deg 46.44% 46.96%);
}  

h1 {
    font-size: 3rem;

}

h2 {
    font-size: 2rem;
}

a {
    color: var(--color-gray-100);
    text-decoration: none;

}

img {
    display: block;
    object-fit: cover;
    width: 30%;

}

.btn {
    color: var(--color-gray-100);
    width: fit-content;
    margin: 0 auto;
    padding: 0.9rem 2rem;
    background: var(--color-primary);
    border-radius: 1.5rem;
    transition: var(--transition);

}
.btn:hover {
    background: rgb(36, 42, 130);
    color:var(--color-gray-600);
}

.btn.lg{
    padding: 1.2rem 3rem;
    border-radius: 2rem;
    font-size: 1.1rem;
}

.btn.sm {
    padding: 0.4rem 1.2rem;
    font-size: 0.9rem;

}

.btn.sm:hover {
    background: green;
    
}
