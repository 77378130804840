#main__header{
    /* background-image: url("/src/images/home_baner.png"); */
    /* color: rgb(90, 28, 156); */
    height: 200px; /* You must set a specified height */
    width: 100%;
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* opacity: 0.9; */
}
#header_fonts{
    font-size: 30px;
    /* text-align: justify; */
    /* color: antiquewhite; */
    color:beige;
}
#header_text
{
    padding-top: 20rem;
}
