.main__header {
    width: 100vm;
    height: calc(100vh - 3rem);
    display: grid;
    place-items: center;
    margin-top: 3rem;
}

.main__header-container {
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    justify-content: space-between;
    gap: 6rem;
}

.read_more
{
    
    border-radius: 10px !important;
    height: 45px;
    text-align: center !important;
    padding: 12px !important;
    margin: 5px;
}
.main__header-left {
    margin-top: -3rem;
}


.main__header-left h4 {
    margin-bottom: 1rem;
    color: var(--color-secondary);
    font-weight: 400;

}

.main__header-left p {
    margin: 1rem 0 3.5rem;
    font-size: 1.1rem;

}

.main__header-right {
    display: grid;
    place-items: center;
    position: relative;

}

.main__header-circle {
    width: 25rem;
    height: 25rem;
    background: linear-gradient(75deg, var(--color-secondary),transparent);
    border-radius: 50%;
    position: absolute;
    transition: var(--transition);
}
.main__header-image {
    position: relative;
    /* padding-top: 13rem; */
}

.main__header-right:hover .main__header-circle {
    filter: blur(1px);
}

.violation_container
{
    margin-top: -2rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.violation_item:hover{
    /* opacity: 0.9; */
    color: rgb(11, 11, 59);
    transform: scale(1.05); 
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}
textarea.news_description{
    overflow: hidden;
    height:auto;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;

    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    /* word-spacing: -0.05em; */
    word-spacing: -0.5px;

    font-size: 16px;


}

textarea.news_description:focus
{
    outline:none;
    border: 0;
    border: 0;
    box-shadow: none;
    /* resize: none; */

}

div.news_description{
    white-space: pre-wrap;
    text-align: justify;
    font-size: 16px;
    /* text-indent: 50px; */
    line-height: 23px;

    
    
}

p.title{
    color:rgb(27, 21, 68);
    font-size: 1.5rem;
    line-height: 24px;
    word-spacing: 0.1em;
    letter-spacing: normal;
    
}

p.time{
    color: gray;
    font-size: 0.9rem;
    line-height: 1px;
}
p.author
{
    font-size: 1rem;
}

img.news_photo{
    height:auto;
    width: auto;
}

img#open_news_image
{
    cursor: pointer;
    transform: scale(1.05); 
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

a#open_news
{
    cursor: pointer;
    margin-top: 10px;
    line-height: 22px;
    color: #4141ac;
    text-align: center;
}

div.data_left{
    padding-top: 1rem;
    
}

#col_content{
    background-color: #4141ac;
    color: azure;
}

.card_header
{
    background-color: rgb(16 89 150);
    width: fit-content;
    color: white;
    line-height: 22px;
    /* text-align: justify; */
    /* hyphens: auto; */
    /* -webkit-hyphens: auto; */
    /* word-spacing: -0.05em; */

   
    /* text-shadow: 1px 0.5px #e2f286; */
    /* font-weight: bold; */
    
}
.c_header{
    background-color: rgb(16 89 150);
    width: fit-content;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    position: absolute;
    box-shadow: 5px 5px 30px rgb(59, 59, 69);
    border-radius: 2px 7px 2px 7px !important;
    border-bottom: 1px dotted rgb(231, 223, 60);


}

.c_header:hover
{
    box-shadow: 5px 5px 30px rgb(48, 48, 163);

}

div.news{
    background-color: hsl(207.31deg 80.72% 32.55%);
    border: none !important;
}


.u_violation
{
    /* background-color: #d9d9d9; */
    background-color: rgb(16 89 150);

    /* border: none; */
    border: none !important;
    border-radius: 0px !important;
    
}

form.user_data{
    background-color: rgb(255, 255, 255);
    padding: 30px;
    border-radius: 5px 10px 5px 10px !important;

}
div.slide_show1{
    /* background-color: azure; */
    background: rgba(76, 175, 80, 0.5);
    color: white;
    border-radius: 2px 7px 2px 7px !important;
    min-height: 350px !important;



}

div.slide_show1 > *{
    padding: 20px;
    /* color: white !important; */
}

div.slide_show1 > h5{
    padding: 20px;
    color: rgb(237, 254, 48) !important;
    
}

.carousel-control-prev-icon, .carousel-control-next-icon
{
    color: green !important;
    /* background: #882727; */
    /* background: rgba(237, 249, 61, 0.5); */

    border: 2px solid rgb(202, 244, 12) !important; 
    border-radius: 30px !important;
}


p.all_section
{
    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    word-spacing: -0.05em;
    /* line-height: 22px; */
    border: none;
    font-size: 14px;



}

textarea.data_description
{
    overflow: hidden;
    height:auto;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;

    text-align: justify;
    hyphens: auto;
    -webkit-hyphens: auto;
    /* word-spacing: -0.05em; */
    word-spacing: -0.5px;
    background-color: transparent;
    color: rgb(243, 241, 133);
}
textarea.data_description:focus
{
    background-color: transparent;
    overflow: hidden;
    height:auto;
    border: none;
    outline: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none;
}

.data_title
{
    color: azure;
}
div.all_text
{
    /* border-left: 2px solid rgba(232, 39, 39, 0.856); */
    /* line-height: 10px; */
    top: 35%;
    font-size: 22px;
    
}

p.report_violation{
    font-size: 16px;
    color: beige;
}

div.user_report
{
    background-color: #2e8473;
}

/* div.report_text
{
    border-right: 2px solid gold;
} */

div.page_content
{
    padding: 2rem;
}