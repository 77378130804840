.data_container
{
 margin-top: 1rem;
 
 border-radius: 2px 7px 2px 7px !important;


}
img.gender_ap_photo
{
    filter: grayscale(20%);
    /* filter: blur(0.5px); */
    opacity: 0.6;
    height: 100%;
    width: 100%;
}

.data_container:hover{
    /* color: rgb(11, 11, 59); */
    opacity: 0.8;
    transform: scale(1.01); 
    -webkit-transition: all 0.25s ease-in-out;
    -moz-transition: all 0.25s ease-in-out;
    -o-transition: all 0.25s ease-in-out;
    transition: all 0.25s ease-in-out;
}

div.data_container
{
    position: relative;

}


div.gender_title
{
  position: absolute;
  padding-top: 5%;
  padding-left: 15%;
  padding-right: 15%;
  line-height: 30px;
  top:10%

  /* padding: 0 1rem; */
  /* top: 50%; */
  /* left: 50%; */
  /* transform: translate(-50%, -50%); */
  /* font-size: 8px; */
  /* z-index: 999; */
}
div.gender_title p{
    
        position: relative;
        color: #ffffff;  
        /* font-size: 14rem; */
        text-align: center;
        opacity: 1;
      
}

p.g_date
{
    font-size: 14px;
    background-color: rgba(35, 54, 177, 0.555);

}

p.g_title
{
    font-size: 18px;
    line-height: 1.5rem;
    background-color: rgba(35, 54, 177, 0.555);
}

div.gen_ap
{
    padding: 20px;
}