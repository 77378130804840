#contact_baner{
    
    
    background-image: url("/src/images/contact_banar.jpg");
    height: 700px; /* You must set a specified height */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    background-size: cover; /* Resize the background image to cover the entire container */
    /* opacity: 0.4; */
}

.text_style
{
    color:#080874;
}
.label_style
{
    color:#ffdd5f;
}
p {
    font-size: 20px;
}
/* 
#contract_form{
    opacity: 1 !important;
    border: 1 px solid gray;
    min-height: 100px;
    min-width: 70%;
    background-color: greenyellow;
} */